import { isServer } from 'solid-js/web';

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

export interface LogEvent {
  timestamp: string;
  level: LogLevel;
  category: string;
  message: string;
  details?: Record<string, unknown>;
  error?: Error;
}

export interface LoggerConfig {
  minLevel?: LogLevel;
  enableTrace?: boolean;
}

// Utility function to safely capture stack trace
function captureStackTrace(error: Error, constructor?: Function) {
  if ((Error as any).captureStackTrace) {
    // Check if it exists (Node.js)
    (Error as any).captureStackTrace(error, constructor);
  } else {
    // Browser fallback
    const stackProperty = {
      configurable: true,
      writable: true,
      value: new Error().stack,
    };
    Object.defineProperty(error, 'stack', stackProperty);
  }
}

// Add type declaration
declare const process: {
  env: {
    [key: string]: string | undefined;
  };
};

/**
 * Simple logger class to handle logging events
 *
 * [Console Logging Tricks](https://www.youtube.com/watch?v=8EA1cu-6exw)
 *
 *  @example
 * const logger = new Logger('MyCategory', { minLevel: LogLevel.DEBUG });
 * logger.debug('Debug message', { key: 'value' });
 * logger.info('Info message');
 * logger.warn('Warning message');
 * logger.error('Error message', new Error('Something went wrong'));
 *
 */
export class Logger {
  private static instance: Logger;

  readonly #category: string;
  readonly #minLevel: LogLevel;
  readonly #enableTrace: boolean;
  #handler: (event: LogEvent) => void;

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger('Global');
    }
    return Logger.instance;
  }

  constructor(category: string, config: LoggerConfig = {}) {
    // console.log('Logger constructor called');
    this.#category = category;
    this.#minLevel = config.minLevel ?? LogLevel.INFO;
    this.#enableTrace = config.enableTrace ?? true;
    this.#handler = Logger.defaultHandler;
  }

  setHandler(handler: (event: LogEvent) => void): void {
    this.#handler = handler;
  }

  debug(message: string, details?: Record<string, unknown>): void {
    this.#log(LogLevel.DEBUG, message, details);
  }

  info(message: string, details?: Record<string, unknown>): void {
    // console.log('Logger.info called');
    this.#log(LogLevel.INFO, message, details);
  }

  warn(message: string, details?: Record<string, unknown>): void {
    this.#log(LogLevel.WARN, message, details);
  }

  error(
    message: string,
    error?: Error | any,
    details?: Record<string, unknown>,
  ): void {
    this.#log(LogLevel.ERROR, message, details, error);
  }

  private static defaultHandler(event: LogEvent): void {
    // Only use console.log in development
    if (process.env.NODE_ENV === 'development') {
      const { timestamp, level, category, message, details, error } = event;
      if (!isServer || error) {
        console.group(
          `[${timestamp}] ${LogLevel[level]} [${category}] ${message}`,
          details || '',
        );
        // if (details) console.log('details:', details);
        if (error) console.error('error:', error);
        console.groupCollapsed('-- stack --');
        console.trace();
        console.groupEnd();
        console.groupEnd();
      } else {
        console.log(
          `[${timestamp}] ${LogLevel[level]} [${category}] ${message}`,
          details || '',
        );
      }
    }
  }

  #log(
    level: LogLevel,
    message: string,
    details?: Record<string, unknown>,
    error?: Error,
  ): void {
    // if (level < this.#minLevel) return;

    const event: LogEvent = {
      timestamp: new Date().toISOString(),
      level,
      category: this.#category,
      message,
      details: this.#sanitizeDetails(details),
      error,
    };

    if (this.#enableTrace) {
      captureStackTrace(event as any, this.#log);
      //   Error.captureStackTrace(event as any, this.#log);
    }

    if (isServer) {
      // TODO(pbirch): This is a hack that might work until we figure out why the server logging wasn't working
      console.log('Logger.#log called', event);
    }
    this.#handler(event);
  }

  #sanitizeDetails(
    details?: Record<string, unknown>,
  ): Record<string, unknown> | undefined {
    if (!details) return undefined;

    const sanitized: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(details)) {
      if (this.#isSensitiveKey(key)) {
        sanitized[key] = '[REDACTED]';
      } else {
        sanitized[key] = value;
      }
    }
    return sanitized;
  }

  #isSensitiveKey(key: string): boolean {
    const sensitivePatterns = [
      /password/i,
      /secret/i,
      /token/i,
      /key/i,
      /auth/i,
      /credential/i,
    ];
    return sensitivePatterns.some((pattern) => pattern.test(key));
  }
}
